import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay } from "swiper";
import img1 from "../../../../../Assets/Images/Home/section2/img-02-1.png";
import img2 from "../../../../../Assets/Images/Home/section2/img-02-2.png";
import img3 from "../../../../../Assets/Images/Home/section2/img-02-3.png";
import img4 from "../../../../../Assets/Images/Home/section2/img-02-4.png";
import img5 from "../../../../../Assets/Images/Home/section2/img-02-5.png";
import "./SectionTwo.scss";
import "swiper/scss";
import "swiper/scss/autoplay";
import "swiper/scss/effect-fade";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../../../../Utils/useWindowSize/useWindowSize";
import Slider from "react-slick";

const settings = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2000,
  centerPadding: "20px",
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
        dots: true,
      },
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        dots: true,
      },
    },
  ],
};

function SectionTwo() {
  const products_recommendList = [
    {
      img: img1,
      text: "Imagination",
    },
    {
      img: img2,
      text: "Pleasure",
    },
    {
      img: img3,
      text: "Curiosity",
    },
    {
      img: img4,
      text: "Movements",
    },
    {
      img: img5,
      text: "Influence",
    },
  ];
  const { t } = useTranslation();
  const { width } = useWindowSize();

  return (
    <div className="section-two ">
      <div className="section-two-slogan">
        <h1>{t("section2Head")}</h1>
      </div>
      <div className="section-two-content">
        {width > 767 ? (
          <>
            {products_recommendList.map((item) => (
              <div className="section-two-item">
                <img src={item.img} alt="img section2" />
                <p>{item.text}</p>
              </div>
            ))}
          </>
        ) : (
          <>
            <Slider {...settings}>
              {products_recommendList.map((item) => (
                <div className="section-two-item">
                  <img src={item.img} alt="img section2" />
                  <p>{item.text}</p>
                </div>
              ))}
            </Slider>
          </>
        )}
      </div>
      <div className="section-two-desc">{t("section2Desc")}</div>
    </div>
  );
}

export default SectionTwo;
