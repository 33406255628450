import gsap from 'gsap';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './Contacts.scss';

function Contacts() {

  const { t } = useTranslation();

  const tl: any = useRef()
  const timeLine = tl.current = gsap.timeline()

  useEffect(() => {
    timeLine.from('.slide-right', {
      x: '-50px',
      opacity: 0,
      duration: .7,
      stagger: {
        each: 0.3,
      },
    });
    timeLine.from('.slide-bottom', {
      y: '50px',
      opacity: 0,
      duration: .7,
      stagger: {
        each: 0.3,
      },
    }, '+1');
  }, []);


  return (
    <div className="contact">
      <div className="contact-container">
        <h2 className="contact-title slide-right">Contact us</h2>
        <div className="contact-main">
          <div className="contact-detail slide-right">
            <div className="top">
              <div className="top-items">
                <p className="title slide-bottom">Work With Us.</p>
                <a href="#" className="link slide-bottom">
                  <p>
                    creators@oopsee.net
                  </p>
                </a>
              </div>
              <div className="top-items">
                <p className="title slide-bottom">Careers.</p>
                <a href="#" className="link slide-bottom">
                  <p>
                    creators@oopsee.net
                  </p>
                </a>
              </div>
            </div>
            <div className="bottom slide-bottom">
              <p>
                {t('topContact')} <br /> {t('botContact')} <br />
              </p>
            </div>
          </div>
          <div className="contact-map ">
            <iframe
              title="looclocation"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3162.295204561722!2d126.97009881566808!3d37.57166577979692!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca293a4a597b5%3A0xad4a9e1913b53d39!2z7ISc7Jq47Yq567OE7IucIOyiheuhnOq1rCDsg4jrrLjslYjroZwz6ri4IDE1!5e0!3m2!1sko!2skr!4v1673227951919!5m2!1sko!2skr"
              frameBorder="0"
              allowFullScreen
              className="slide-right"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contacts;
