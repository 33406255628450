import React from 'react';
import logo from '../../../../Assets/Images/Works/25x28.png';

function Footer() {
  return (
    <div className="footer">
      <div className="container" style={{ padding: '0 20px' }}>
        <div className="footer-main">
          <a href="/" className="footer-logo">
            <img src={logo} alt="logo" />
          </a>
          <a
            href="https://vimeo.com/loocreative"
            target="_blank"
            rel="noreferrer"
          >
            VIMEO
          </a>
          <a
            href="https://www.instagram.com/loocreative/"
            target="_blank"
            rel="noreferrer"
          >
            YOUTUBE
          </a>
          <a
            href="https://www.instagram.com/loocreative/"
            target="_blank"
            rel="noreferrer"
          >
            INSTAGRAM
          </a>
          <a
            href="https://www.facebook.com/loocreativeinc-110670107091286/"
            target="_blank"
            rel="noreferrer"
          >
            FACEBOOK
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
