export const historyData = [
    {
      year: '2024',
      index: 0,
      listItem: [
        {index:0 ,number: "07월", text: "X-ara 첫번째 미니 앨범 “1001001” 발매 예정"}
      ]
    },
    {
      index: 1,
      year: '2023',
      listItem: [
        {index: 0,number: "06월", text: "X-ara 첫번째 미니 앨범 “1001001” 발매 예정"},
        {index: 1,number: "03월", text: "X-ara 네번째 싱글 “Sum Over Histories” 발매 예정"}
      ]
    },
    {
      index: 2,
      year: '2022',
      listItem: [
        {index: 0, number: "12월", text: "X-ara 세번째 싱글 “슈뢰딩거의 고양이” 발매 예정"},
        {index: 1, number: "09월", text: "X-ara 두번째 싱글 “빅뱅” 발매 예정"},
        {index: 2, number: "07월", text: "X-ara 첫번째 싱글 “히치하이커” 발매"}
      ]
    },
  ];
  