import card1Off from "../../../../../Assets/Images/Home/section3/img-01.png";
import card2Off from "../../../../../Assets/Images/Home/section3/img-02.png";
import card3Off from "../../../../../Assets/Images/Home/section3/img-03.png";
import card4Off from "../../../../../Assets/Images/Home/section3/img-04.png";
import card1On from "../../../../../Assets/Images/Home/section3/img-01-02.png";
import card2On from "../../../../../Assets/Images/Home/section3/img-02-02.png";
import card3On from "../../../../../Assets/Images/Home/section3/img-03-02.png";
import card4On from "../../../../../Assets/Images/Home/section3/img-04-02.png";
import { useTranslation } from "react-i18next";
import CardThirdPage from "./CardThirdPage";
import "./SectionThree.scss";
import Slider from "react-slick";

const cardList = [
  {
    index: 0,
    head: "M/V",
    img1: card1Off,
    img2: card1On,
    title: "With ThinkNature",
    text: "X-ARA와 ThinkNature의 환상적인 콜라보! 지금 바로 X-ARA의 다양한 모습들을 확인해보세요.",
    btn: "double",
  },
  {
    index: 1,
    head: "Beauty",
    img1: card2Off,
    img2: card2On,
    title: "With ThinkNature",
    text: "X-ARA와 ThinkNature의 환상적인 콜라보! 지금 바로 X-ARA의 다양한 모습들을 확인해보세요.",
    btn: "double",
  },
  {
    index: 2,
    head: "Movie",
    img1: card3Off,
    img2: card3On,
    title: "Concepts art",
    text: "계속해서 뻗어나가는 X-ARA의 세계관을 살펴볼 수 있습니다. 흥미롭고 놀라운 컨셉을 확인해보세요.",
    btn: "soon",
  },
  {
    index: 3,
    head: "Animation",
    img1: card4Off,
    img2: card4On,
    title: "Scenario",
    text: "X-ARA는 끊임없이 발전하고 있습니다. 다양한 시나리오를 준비하고 있습니다.",
    btn: "soon",
  },
];

const settings = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3.5,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2000,
  centerPadding: "20px",
  responsive: [
    {
      breakpoint: 1800,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
      },
    },
    {
      breakpoint: 1700,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
        dots: true,

      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        dots: true,

      },
    },
  ],
};

function SectionThree() {
  const { t } = useTranslation();

  return (
    <div className="page-three">
      <div className="page-three-container">
        <Slider {...settings}>
          {cardList.map((card: any) => (
            <div
              className={`card-item section-three-item-${card.index + 1}`}
              key={card.index}
            >
              <CardThirdPage
                card={card}
                key={card.index}
                content={t(`section3Data.${card.index}.text`)}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}
export default SectionThree;
