import React from "react";
import "./SectionFive.scss";
import { MailOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import bg from "../../../../../Assets/Images/Home/section4/img.png"

function SectionFive() {
  return (
    <div className="section-five" id="pageFive">
      <div className="section-five-container">
        <div className="section-five-img">
          <img src={bg} alt="bg section 4" />
        </div>
        <div className="section-five-main">
          <div className="section-five-elm">
            <p className="section-five-text">Oopsee</p>
            <div className="section-five-link-wr">
              <p className="section-five-link">
                © 2022 <br /> OOPSEE. All Rights Reserved
              </p>
            </div>
          </div>
          <div className="section-five-wrapper">
            <div className="section-five-wrapper-item">
              <p className="section-five-wrapper-item-text">Adress</p>
              <div className="section-five-link-wr">
                <p className="section-five-link">
                  9F, 15, Saemunan-ro 3-gil, Jongno-gu, Seoul, Republic of Korea<br />
                </p>
              </div>
            </div>
            <div className="section-five-wrapper-item">
              <p className="section-five-wrapper-item-text">Partnership</p>
              <div className="section-five-link-wr">
                <a href="mailto:creators@oopsee.net">creators@oopsee.net</a>
              </div>
            </div>
            <div className="section-five-wrapper-item">
              <p className="section-five-wrapper-item-text">Social</p>
              <div className="section-five-link-social">
                <a href="https://www.instagram.com/oopsee_official/">Instagram</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionFive;
