import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React from "react";
import "./SectionOne.scss";
import logo from "../../../../../Assets/Images/Home/section1/logo.png";
import { useTranslation } from "react-i18next";

function SectionOne() {
  const { t } = useTranslation();

  return (
    <div className="section-one">
      <div className="section-one-container">
        <div className="section-one-main">
          <a href="#!" className="section-one-btn">
            {t("title1")}
          </a>
          <h1 className="section-one-title">{t("title2")}</h1>
          <p className="section-one-text p">{t("title3")}</p>
        </div>
        <div className="section-one-bg">
          <img src={logo} alt="bg" />
        </div>
      </div>
    </div>
  );
}

export default SectionOne;
