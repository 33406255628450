import React, { useState } from 'react';
import img1 from '../../../Assets/Images/Works/1.jpg';
import img10 from '../../../Assets/Images/Works/10.jpg';
import img11 from '../../../Assets/Images/Works/11.jpg';
import img12 from '../../../Assets/Images/Works/12.jpg';
import img13 from '../../../Assets/Images/Works/13.jpg';
import img14 from '../../../Assets/Images/Works/14.jpg';
import img15 from '../../../Assets/Images/Works/15.jpg';
import img16 from '../../../Assets/Images/Works/16.jpg';
import img17 from '../../../Assets/Images/Works/17.jpg';
import img18 from '../../../Assets/Images/Works/18.jpg';
import img19 from '../../../Assets/Images/Works/19.jpg';
import img2 from '../../../Assets/Images/Works/2.jpg';
import img20 from '../../../Assets/Images/Works/20.jpg';
import img3 from '../../../Assets/Images/Works/3.jpg';
import img4 from '../../../Assets/Images/Works/4.jpg';
import img5 from '../../../Assets/Images/Works/5.jpg';
import img6 from '../../../Assets/Images/Works/6.jpg';
import img7 from '../../../Assets/Images/Works/7.jpg';
import img8 from '../../../Assets/Images/Works/8.jpg';
import img9 from '../../../Assets/Images/Works/9.jpg';
import img21 from '../../../Assets/Images/Works/21.png';
import img22 from '../../../Assets/Images/Works/22.png';
import img23 from '../../../Assets/Images/Works/23.png';
import img24 from '../../../Assets/Images/Works/24.png';
import img25 from '../../../Assets/Images/Works/25.png';
import img26 from '../../../Assets/Images/Works/26.png';
import Footer from './Components/Footer';
import MenuTab from './Components/MenuTab';
import WorkBlock from './Components/WorkBlock';
import WorkModal from './Components/WorkModal';

import './Works.scss';

export interface WorkData {
  id: number;
  title: string;
  type: string;
  image: string;
}

const data: WorkData[] = [
  {
    id: 1,
    title: '2021 Looc Showreel',
    type: 'SHOWREEL',
    image: img1,
  },
  {
    id: 2,
    title: 'Opening Title | The serene sea',
    type: 'COMMERCIAL',
    image: img2,
  },
  {
    id: 3,
    title: 'Opening Title | Sabotage City',
    type: 'COMMERCIAL',
    image: img3,
  },
  {
    id: 4,
    title: 'DIGILOCA | Teaser',
    type: 'COMMERCIAL',
    image: img4,
  },
  {
    id: 5,
    title: 'Kona Lucky Card',
    type: 'COMMERCIAL',
    image: img5,
  },
  {
    id: 6,
    title: 'KB | Liiv Next',
    type: 'COMMERCIAL',
    image: img6,
  },
  {
    id: 7,
    title: '로카머니 | LOCAMONEY',
    type: 'COMMERCIAL',
    image: img7,
  },
  {
    id: 8,
    title: 'KLOUD | KLOUD X BTS',
    type: 'COMMERCIAL',
    image: img8,
  },
  {
    id: 9,
    title: 'Shinhan Life. | Brand ID',
    type: 'COMMERCIAL',
    image: img9,
  },
  {
    id: 10,
    title: '2021 Looc Showreel',
    type: 'SHOWREEL',
    image: img10,
  },
  {
    id: 11,
    title: '2021 Looc Showreel',
    type: 'SHOWREEL',
    image: img11,
  },
  {
    id: 12,
    title: '2021 Looc Showreel',
    type: 'SHOWREEL',
    image: img12,
  },
  {
    id: 13,
    title: '2021 Looc Showreel',
    type: 'SHOWREEL',
    image: img13,
  },
  {
    id: 14,
    title: '2021 Looc Showreel',
    type: 'SHOWREEL',
    image: img14,
  },
  {
    id: 15,
    title: '2021 Looc Showreel',
    type: 'SHOWREEL',
    image: img15,
  },
  {
    id: 16,
    title: '2021 Looc Showreel',
    type: 'SHOWREEL',
    image: img16,
  },
  {
    id: 17,
    title: '2021 Looc Showreel',
    type: 'SHOWREEL',
    image: img17,
  },
  {
    id: 18,
    title: '2021 Looc Showreel',
    type: 'SHOWREEL',
    image: img18,
  },
  {
    id: 19,
    title: '2021 Looc Showreel',
    type: 'SHOWREEL',
    image: img19,
  },
  {
    id: 20,
    title: '2021 Looc Showreel',
    type: 'SHOWREEL',
    image: img20,
  },
  {
    id: 21,
    title: '2021 Looc Showreel',
    type: 'GAME DEV',
    image: img21,
  },
  {
    id: 22,
    title: '2021 Looc Showreel',
    type: 'GAME DEV',
    image: img22,
  },
  {
    id: 23,
    title: '2021 Looc Showreel',
    type: 'GAME DEV',
    image: img23,
  },
  {
    id: 24,
    title: '2021 Looc Showreel',
    type: 'GAME DEV',
    image: img24,
  },
  {
    id: 25,
    title: '2021 Looc Showreel',
    type: 'UI DESIGN',
    image: img25,
  },
  {
    id: 26,
    title: '2021 Looc Showreel',
    type: 'UI DESIGN',
    image: img26,
  },
];

function Works() {
  const [type, setType] = useState<string>('ALL');
  const [workList, setWorkList] = useState(data);
  const [showModal, setShowModal] = useState<boolean>(false);

  //handle filter type
  const handleTypeChange = (newType: string) => {
    if (newType === 'ALL') {
      setWorkList(data);
    } else {
      const newWorkList = data.filter((item) => item.type === newType);
      setWorkList(newWorkList);
    }

    setType(newType);
  };

  return (
    <div className={showModal ? 'bg-modal works-page-wr' : 'works-page-wr'}>
      <div className="works-page container">
        <p className="works-title">WORKS</p>
        <MenuTab type={type} onTypeChange={handleTypeChange} />
        <WorkBlock workList={workList} setShowModal={setShowModal} />
        <WorkModal showModal={showModal} setShowModal={setShowModal} />
      </div>
      <Footer />
    </div>
  );
}

export default Works;
