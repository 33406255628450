import i18next from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { RoutesString } from "../../Modules/routesString";
import "./header.scss";
const Header: React.FC = () => {
  const [showMenu, setshowMenu] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const { t } = useTranslation();
  const activeLang = localStorage.getItem("i18nextLng");

  const handleShowMenu = () => {
    setshowMenu(!showMenu);
  };

  return (
    <>
      {/* <a className="arrow" href="#fivePage">
        <BsArrowDown />
      </a> */}
      <div className="header">
        <div className="header_title">
          <a href="#firstPage">Oopsee</a>
        </div>
        <div className="header_box">
          {/* icons bars menu */}
          <div
            className={
              showMenu
                ? "header_box_menu_mobile-tonggle active"
                : "header_box_menu_mobile-tonggle"
            }
            onClick={handleShowMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>

          <div
            className={
              showMenu
                ? "header_box_lang header_box_menu_mobile-icon-close"
                : "header_box_lang"
            }
          >
            <div
              className={
                activeLang === "kr" || !activeLang ? "lang active" : "lang"
              }
            >
              <span onClick={() => i18next.changeLanguage("kr")}>KR</span>
            </div>
            <div className={activeLang == "en" ? "lang active" : "lang"}>
              <span onClick={() => i18next.changeLanguage("en")}>EN</span>
            </div>
          </div>

          {/* menu */}
          <div
            className={showMenu ? "header_box_menu active" : "header_box_menu"}
          >
            {/* <div className="header_box_menu_mobile-icon-close">
              <div className="header_box_lang">
                <div
                  className={
                    activeLang == "kr" || !activeLang ? "lang active" : "lang"
                  }
                >
                  <span onClick={() => i18next.changeLanguage("kr")}>KR</span>
                </div>
                <div className={activeLang == "en" ? "lang active" : "lang"}>
                  <span onClick={() => i18next.changeLanguage("en")}>EN</span>
                </div>
              </div>
            </div> */}

            <div className="menu">
              <NavLink exact to={RoutesString.Home} onClick={handleShowMenu}>
                Home
              </NavLink>
              <NavLink
                className="disabled-link"
                to={isActive ? "#" : RoutesString.Works}
                onClick={handleShowMenu}
              >
                Works
              </NavLink>
              <NavLink to={RoutesString.Contacts} onClick={handleShowMenu}>
                Contact
              </NavLink>
            </div>

            <div className="infoWrapper">
              <div id="adress">
                {t("Address")}
                <br />
                <p style={{ color: "#aeaeae" }}>
                  9F, 15, Saemunan-ro 3-gil, Jongno-gu, Seoul, Republic of Korea
                </p>
              </div>
              <p className="link">
                <a
                  href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3162.295204561722!2d126.97009881566808!3d37.57166577979692!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca293a4a597b5%3A0xad4a9e1913b53d39!2z7ISc7Jq47Yq567OE7IucIOyiheuhnOq1rCDsg4jrrLjslYjroZwz6ri4IDE1!5e0!3m2!1sko!2skr!4v1673227951919!5m2!1sko!2skr"
                  target="blank"
                >
                  View Map
                </a>
              </p>
              <br />
              <p>Work with us.</p>
              <p className="link">
                <a href="mailto:creators@oopsee.net">creators@oopsee.net</a>
              </p>
              <br />
              <p>Recruiting</p>
              <p className="link">
                <a href="mailto:creators@oopsee.net">creators@oopsee.net</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
