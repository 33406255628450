import React, { useEffect, useState } from "react";
import useWindowSize from "../../../../../../Utils/useWindowSize/useWindowSize";
import "./CardThirdPage.scss";
import styled from "styled-components";

type Props = {
  card: CardProps;
  content?: string;
};
export interface CardProps {
  head: string;
  title: string;
  img1: string;
  img2: string;
  text: string;
  btn: string;
}

const CardThirdPage = ({ card, content }: Props) => {
  const [hover, setHover] = useState<boolean>(false);

  const i18nextLng = localStorage.getItem("i18nextLng");

  const { width, height } = useWindowSize();

  const [lengthContent, setLengthContent] = useState<number>(185);

  useEffect(() => {
    if (i18nextLng === "en") {
      if (width > 1080) {
        setLengthContent(200);
      } else if (width >= 768) {
        setLengthContent(165);
      } else if (width > 576) {
        setLengthContent(145);
      } else {
        setLengthContent(140);
      }
    } else {
      if (width > 1080) {
        setLengthContent(200);
      } else if (width >= 768) {
        setLengthContent(120);
      } else if (width > 576) {
        setLengthContent(100);
      } else {
        setLengthContent(80);
      }
    }
  }, [width]);

  const bg = hover ? card.img2 : card.img1;

  return (
    <div
      className="card-container"
      onMouseLeave={() => setHover(false)}
      onMouseOver={() => setHover(true)}
      style={{ backgroundImage: `url(${bg})`, transition: "all 0.5s ease" }}
    >
      <h2 className="card-head">
        X-ARA
        <br />
        {card.head}
      </h2>
      <div className="card-box">
        <h3 className="card-title">{card.title}</h3>
        <p className="card-desc">{content}</p>
        <div className="card-btn">
          {card.btn === "double" ? (
            <>
              <span>KOKOFIT</span>
              <span>X-ARA</span>
            </>
          ) : (
            <span className="card-btn-one">SOON</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardThirdPage;
