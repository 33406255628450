import gsap from 'gsap';
import React, { useEffect, useRef } from 'react';
import './Teams.scss';

function Teams() {
  return (
    <div className="teams-page" >
     
    </div>
  );
}

export default Teams;
